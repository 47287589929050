import { createContext, useReducer } from "react";
import { getLocalTheme } from "utils/common";

export const ThemeContext = createContext();
const localTheme = getLocalTheme();
const initialState = {
  isDarkMode: localTheme === "true",
  activeCollections: {},
};

const themeReducer = (state, action) => {
  switch (action.type) {
    case "LIGHT_MODE":
      localStorage.setItem("dark-theme", false);
      return { ...state, isDarkMode: false };
    case "DARK_MODE":
      localStorage.setItem("dark-theme", true);
      return { ...state, isDarkMode: true };
    case "ACTIVE_COLLECTIONS":
      return { ...state, [action.field]: action.value };
    default:
      return state;
  }
};

export const ThemeProvider = (props) => {
  const [state, dispatch] = useReducer(themeReducer, initialState);
  return (
    <ThemeContext.Provider value={{ state: state, dispatch: dispatch }}>
      {props.children}
    </ThemeContext.Provider>
  );
};
