import { createSlice } from "@reduxjs/toolkit";

export const epochSlice = createSlice({
  name: "epoch",
  initialState: {
    value: 0,
  },
  reducers: {
    setEpoch: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = action.payload;
    },
  },
});

// export const validatorSlice = createSlice({
//   name: "validatorList",
//   initialState: {
//     list: {},
//   },
//   reducers: {
//     setValidatorList: (state, action) => {
//       state.list = action.payload;
//     },
//   },
// });

// Action creators are generated for each case reducer function
export const { setEpoch } = epochSlice.actions;
// export const { setValidatorList } = validatorSlice.actions;

// export const epochSlice.reducer;
// export default validatorSlice.reducer;
