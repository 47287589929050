import React, { useEffect, useState, lazy, Suspense } from "react";
import ReactDOM from "react-dom";
import { Route } from "react-router-dom";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import { WagmiConfig, configureChains, createClient, useNetwork } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import {
  RainbowKitProvider,
  connectorsForWallets,
  lightTheme,
} from "@rainbow-me/rainbowkit";
import { metaMaskWallet } from "@rainbow-me/rainbowkit/wallets";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createBrowserHistory } from "history";
import Loader from "components/Shared/Loader";
import { skaleEuropaTestnet } from "utils/constants";
import "assets/plugins/nucleo/css/nucleo.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/scss/argon-dashboard-react.scss";
import "@rainbow-me/rainbowkit/styles.css";
import { Button } from "reactstrap";
import { Provider } from "react-redux";
import store from "redux/store";
import { ThemeProvider } from "layouts/ThemeLayoutContext";
import { Switch, BrowserRouter } from "react-router-dom/cjs/react-router-dom";
import { NETWORK_CHAIN_IDS } from "utils/constants";
import { GRAPHQL_ENDPOINTS } from "utils/constants";

const DashboardLayout = lazy(() => import("layouts/Dashboard.js"));
const SentryRoute = Sentry.withSentryRouting(Route);

const createApolloClient = (selectedNetwork) => {
  return new ApolloClient({
    uri: GRAPHQL_ENDPOINTS[selectedNetwork] || GRAPHQL_ENDPOINTS.europaTestnet,
    cache: new InMemoryCache(),
  });
};

const ErrorFallback = (error, componentStack, resetError) => {
  console.error("Error : ", error.toString());
  return (
    <div className="error-fallback" role="alert">
      <a className="mb-4 error-txt" href="/">
        Something went wrong. Try reloading the page.
      </a>
      <Button
        color="primary"
        className="m-auto error-btn"
        onClick={() => {
          resetError();
          window.location.href = "/";
        }}
      >
        Back to Home Page <i className="fas fa-arrow-left" />
      </Button>
      <div className="page-rzr-loader">
        <Loader type="line-scale-pulse-out" />
      </div>
    </div>
  );
};

const history = createBrowserHistory();
const location = window.location.hostname;
if (location !== "localhost") {
  Sentry.init({
    dsn: "https://d062a5a66bf74828955c93289b59cf4e@o1070194.ingest.sentry.io/6065829",
    replaysSessionSampleRate: 0.05,
    replaysOnErrorSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      new Sentry.Integrations.Breadcrumbs({ console: false }),
      new Sentry.Replay({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: true,
      }),
    ],
    tracesSampleRate: 1.0,
    tracingOrigins: ["staging.razorscan.io", "razorscan.io"],
    routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    environment: location === "staging.razorscan.io" ? "dev" : "production",
  });
}

const { chains, provider } = configureChains(
  [skaleEuropaTestnet],
  [
    jsonRpcProvider({
      rpc: () => ({ http: skaleEuropaTestnet.rpcUrls.default.http[0] }),
    }),
  ]
);

const connectors = connectorsForWallets([
  {
    groupName: "Recommended",
    wallets: [
      //   injectedWallet({ chains }),
      //   rainbowWallet({ projectId: "Razorscan", chains }),
      metaMaskWallet({ projectId: "Razorscan", chains }),
      //   coinbaseWallet({ chains, appName: "RazorScan" }),
      //   ledgerWallet({
      //     projectId: "Razorscan",
      //     chains,
      //   }),
    ],
  },
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const App = () => {
  const { chain } = useNetwork();
  const [apolloClient, setApolloClient] = useState(
    createApolloClient("skaleEuropaTestnet")
  );

  useEffect(() => {
    const selectedNetwork =
      Object.keys(NETWORK_CHAIN_IDS).find(
        (network) => NETWORK_CHAIN_IDS[network] === chain?.id
      ) || "skaleEuropaTestnet";
    setApolloClient(createApolloClient(selectedNetwork));
    localStorage.setItem("chainId", chain ? chain?.id : skaleEuropaTestnet.id);
  }, [chain?.id]);

  return (
    <ApolloProvider client={apolloClient}>
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack, resetError }) =>
          ErrorFallback(error, componentStack, resetError)
        }
      >
        <WagmiConfig client={wagmiClient}>
          <RainbowKitProvider
            chains={chains}
            theme={lightTheme({
              accentColor: "#5e72e4",
            })}
            showRecentTransactions={true}
            modalSize="compact"
            showBalance={false}
          >
            <Provider store={store}>
              <ThemeProvider>
                <BrowserRouter>
                  <Suspense
                    fallback={
                      <div className="page-rzr-loader">
                        <Loader type="line-scale-pulse-out" />
                      </div>
                    }
                  >
                    <Switch>
                      <SentryRoute
                        path="/"
                        render={(props) => <DashboardLayout {...props} />}
                      />
                    </Switch>
                  </Suspense>
                </BrowserRouter>
              </ThemeProvider>
            </Provider>
          </RainbowKitProvider>
        </WagmiConfig>
      </Sentry.ErrorBoundary>
    </ApolloProvider>
  );
};

(async () => {
  ReactDOM.render(<App />, document.getElementById("root"));
})();
