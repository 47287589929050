import React from "react";
import ReactLoader from "react-loaders";

const Loader = ({ type }) => {
  return (
    <div className="rzr-loader">
      <ReactLoader color="#5e72e4" type={type} />
    </div>
  );
};

export default Loader;
