import moment from "moment";
import dayjs from "dayjs";
import { ethers } from "ethers";
import { RPC_URLS, blockExplorerUrl } from "./constants";
export const tokenAddress = process.env.REACT_APP_TOKEN_ADDRESS;
const chainId = localStorage.getItem("chainId");
export const stateLength =
  chainId == "11155420" || chainId == "undefined" ? 60 : 75;
export const numberOfStates = process.env.REACT_APP_NUMBER_OF_STATES;
export const totalEpochs =
  chainId == "11155420" || chainId == "undefined" ? 105120 : 70080;
export const blockTime = 1;
export const legends = {
  1: "ETHUSD",
  2: "BTCUSD",
  3: "GOLDUSD",
};
const epochLengthVar =
  chainId == "11155420" || chainId == "undefined" ? 300 : 450;
const epochTime = epochLengthVar * blockTime;
const stateTime = stateLength * blockTime;
const API_URL =
  chainId == "11155420" || chainId == "undefined"
    ? process.env.REACT_RAZOR_APP_API_URL
    : process.env.REACT_EUROPA_APP_API_URL;

export const url = API_URL || "http://localhost:3001";
export const faucetLink = "https://faucet.razorscan.io/";
export const bridgeLink = "https://bridge.razorscan.io/";
export const docs = "https://docs.razor.to/";
export const discord = "https://discord.com/invite/Js4pBny2rw";
export const twitter = "https://twitter.com/razor_network/";
export const telegram = "https://t.me/razornetwork";
export const contractAddressPolygon =
  "0xc91c06db0f7bffba61e2a5645cc15686f0a8c828";
export const contractAddressEth = "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd";
export const contractAddressBsc = "0x50de6856358cc35f3a9a57eaaa34bd4cb707d2cd";
export const razorTokenSkaleAddress =
  "0xcbf70914Fae03B3acB91E953De60CfDAaCA8145f";
export const CHART_HEIGHT = 150;
export const COLORS = [
  "#ee64b8",
  "#ff8d00",
  "#00bfea",
  "#8884ff",
  "#ab6100",
  "#c90000",
  "#7b7b7b",
  "#6464ff",
  "purple",
  "darkgreen",
];
const numberFmt0 = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const numberFmt1 = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});
const numberFmt2 = Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});
const currencyFmt0 = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});
const currencyFmt1 = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 1,
});
const currencyFmt2 = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
});

export const requestSort = (key, sortConfig) => {
  let direction = "desc";
  if (sortConfig && sortConfig.key === key && sortConfig.direction === "desc") {
    direction = "asc";
  }
  return { key, direction };
};

export const isTransactionTab = () => {
  if (
    window.location.pathname === "/transactions" ||
    window.location.pathname.startsWith("/epochs") ||
    window.location.pathname.startsWith("/stats")
  )
    return true;
  return false;
};

export const isExploreTab = () => {
  if (
    window.location.pathname === "/bridge" ||
    window.location.pathname.startsWith("/assets")
  )
    return true;
  return false;
};

export const isGovernanceTab = () => {
  if (window.location.pathname.startsWith("/governance")) return true;
  return false;
};

export const getClassNamesFor = (name, sortConfig) => {
  if (!sortConfig) {
    return;
  }
  return sortConfig.key === name ? sortConfig.direction : undefined;
};

export const convertTime = (
  initiateWithdraw,
  currentEpoch,
  currentState,
  accomplishment,
  isInitiateWithdrawAfter
) => {
  let num;
  if (isInitiateWithdrawAfter) {
    num =
      (parseInt(initiateWithdraw) - 1 - currentEpoch) * epochTime +
      (numberOfStates - currentState - 1) * stateTime +
      ((100 - accomplishment) * stateTime) / 100;
  } else {
    num =
      (parseInt(initiateWithdraw) - currentEpoch) * epochTime +
      (numberOfStates - currentState - 1) * stateTime +
      ((100 - accomplishment) * stateTime) / 100;
  }
  if (num < 0) return "0h 0m";
  let hours = Math.floor(num / 3600);
  let minutes = Math.floor((num % 3600) / 60);
  return hours + "h " + minutes + "m";
};

export const convertTimeObj = (
  initiateWithdraw,
  currentEpoch,
  currentState,
  accomplishment,
  isInitiateWithdrawAfter
) => {
  let num;
  if (isInitiateWithdrawAfter) {
    num =
      (parseInt(initiateWithdraw) - 1 - currentEpoch) * epochTime +
      (numberOfStates - currentState - 1) * stateTime +
      ((100 - accomplishment) * stateTime) / 100;
  } else {
    num =
      (parseInt(initiateWithdraw) - currentEpoch) * epochTime +
      (numberOfStates - currentState - 1) * stateTime +
      ((100 - accomplishment) * stateTime) / 100;
  }
  if (num < 0) return "0h 0m";
  let hours = Math.floor(num / 3600);
  let minutes = Math.floor((num % 3600) / 60);
  return { hours, minutes };
};

export const estimateTime = (num) => {
  if (num < 0) return "0h 0m";
  let hours = Math.floor(num / 3600);
  let minutes = Math.floor((num % 3600) / 60);
  return hours + "h " + minutes + "m";
};

// export const getProvider = () => {
//   const providers = ["infura", "geth", "pokt"];
//   let provider = localStorage.getItem("PROVIDER") || "geth";
//   // If the localStorage was manually changed
//   if (!providers.includes(provider)) provider = "geth";
//   return provider;
// };

export const pageTransition = {
  pageContainer: {
    initial: {
      opacity: 0,
      x: 0,
    },

    animate: {
      opacity: 1,
      x: 0,
    },

    exit: {
      opacity: 0,
      x: 0,
    },

    transition: {
      x: { type: "spring", stiffness: 500, damping: 30 },
      ease: [0.17, 0.67, 0.83, 0.67],
    },
  },
};

export const getLocalTheme = () => {
  const themeValue = window.localStorage.getItem("dark-theme");
  return themeValue || "true";
};

export const roundOffValue = (value, precision) => {
  var multiplier = Math.pow(10, precision || 0);
  return Math.round(value * multiplier) / multiplier;
};

export const getProfitLoss = (stake, totalSupply, delegatedAmount, sAmount) => {
  try {
    let currentRazorBal;
    if (sAmount > 0) {
      currentRazorBal = Number((sAmount * stake) / totalSupply);
      const result = currentRazorBal - Number(delegatedAmount) || 0;
      return {
        profitLoss: isFinite(result) ? result : 0,
        currentRazorBal,
      };
    }
    return {
      profitLoss: null,
      currentRazorBal: 0,
    };
  } catch (error) {
    console.log("Error calculating returns", error);
    return {
      profitLoss: null,
      currentRazorBal: 0,
    };
  }
};

export const getCurrentBlock = async () => {
  try {
    const chainId = localStorage.getItem("chainId");
    const provider = new ethers.providers.JsonRpcProvider(
      RPC_URLS[chainId] || RPC_URLS["11155420"]
    );
    const currentBlock = await provider.getBlockNumber();
    return currentBlock;
  } catch (e) {
    console.log("Connect to metmask to fetch state");
    return null;
  }
};

export const getBlockTimestamp = async (blockNumber) => {
  try {
    const chainId = localStorage.getItem("chainId");
    const provider = new ethers.providers.JsonRpcProvider(
      RPC_URLS[chainId] || RPC_URLS["11155420"]
    );
    const block = await provider.getBlock(blockNumber);
    return block.timestamp;
  } catch (e) {
    console.log("Connect to metmask to get current block timestamp");
    return null;
  }
};

export const getEpoch = async () => {
  try {
    const chainId = localStorage.getItem("chainId");
    const epochLengthVar =
      chainId == "11155420" || chainId == "undefined" ? 300 : 450;
    const currentBlockNumber = await getCurrentBlock();
    const blockTimestamp = await getBlockTimestamp(currentBlockNumber);
    const epoch = Math.floor(blockTimestamp / epochLengthVar);
    return epoch;
  } catch (e) {
    console.log("Error fetching epoch", e);
    return null;
  }
};

export const getCurrentState = async () => {
  const currentBlock = await getCurrentBlock();
  const blockTimestamp = await getBlockTimestamp(currentBlock);
  //Commenting state buffer implementation
  // const buffer = 20;
  // if (!currentBlock) return "-";
  // const lowerLimit = (stateLength * buffer) / 100;
  // const upperLimit = stateLength - (stateLength * buffer) / 100;
  // if (
  //   currentBlock % stateLength > upperLimit ||
  //   currentBlock % stateLength < lowerLimit
  // )
  //   return `Buffer`;
  let response = Math.floor(blockTimestamp / stateLength);
  return response % numberOfStates;
};

export const getStateFromBlockNumber = (blockNumber) => {
  return Math.floor(blockNumber / stateLength) % numberOfStates;
};

export const getStateAccomplishment = async (blockNumber) => {
  const timestamp = await getBlockTimestamp(blockNumber);
  return Math.floor(((timestamp % stateLength) / stateLength) * 100);
};

export const stateList = {
  Buffer: "Buffer",
  0: "Commit",
  1: "Reveal",
  2: "Propose",
  3: "Dispute",
  4: "Confirm",
};

export const urlSkale = `${blockExplorerUrl}tx/`;
// export const urlTenderly = "https://dashboard.tenderly.co/tx/mumbai/";
export const urlSkaleStaker = `${blockExplorerUrl}address/`;
export const urlSkaleToken = `${blockExplorerUrl}token/`;

export const tokenInfo = {
  name: "Razor Network",
  address: tokenAddress,
  symbol: "RAZOR",
  decimals: 18,
  image: "https://s2.coinmarketcap.com/static/img/coins/64x64/8409.png",
};

export const skaleTestnetNetworkInfo = {
  chainName: "Skale V3 Testnet",
  name: "SKALE",
  rpcUrls: ["https://staging-v3.skalenodes.com/v1/staging-aware-chief-gianfar"],
  symbol: "ETH",
  decimals: 18,
  iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/8409.png"],
  blockExplorerUrls: [
    "https://staging-aware-chief-gianfar.explorer.staging-v3.skalenodes.com/",
  ],
};

export const skaleNetworkInfo = {
  chainName: "Skale V3 Testnet",
  name: "SKALE",
  rpcUrls: ["https://staging-v3.skalenodes.com/v1/staging-aware-chief-gianfar"],
  symbol: "ETH",
  decimals: 18,
  iconUrls: ["https://s2.coinmarketcap.com/static/img/coins/64x64/8409.png"],
  blockExplorerUrls: [
    "https://staging-aware-chief-gianfar.explorer.staging-v3.skalenodes.com/",
  ],
};

export const razorSchain = {
  id: 278611351,
  name: "Razor Skale Chain",
  network: "turbulent-unique-scheat",
  iconBackground: "#fff",
  nativeCurrency: {
    decimals: 18,
    name: "Skale",
    symbol: "ETH",
  },
  rpcUrls: {
    default: "https://mainnet.skalenodes.com/v1/turbulent-unique-scheat",
  },
  blockExplorers: {
    default: {
      name: "Skalenodes",
      url: "https://turbulent-unique-scheat.explorer.mainnet.skalenodes.com/",
    },
  },
};

export const calculateSRazor = (
  stake,
  totalSupply,
  epochFirstRevealed,
  currentEpoch,
  commission
) => {
  const currentReturns = ((stake - totalSupply) / totalSupply) * 100;
  if (Math.round(currentReturns) === -100) return 0; // staker has been slashed
  const epochActiveSince = currentEpoch - epochFirstRevealed;
  const annualisedReturnsWithoutCommission =
    (currentReturns / epochActiveSince) * totalEpochs;
  const aprFactor = 1 - parseFloat(commission / 100).toFixed(2);
  const annualisedReturns = parseFloat(
    annualisedReturnsWithoutCommission * aprFactor
  ).toFixed(2);
  return isFinite(annualisedReturns) ? annualisedReturns : 0;
};

export const isStakerActive = (currentEpoch, epochLastRevealed) => {
  return currentEpoch - epochLastRevealed <= 4;
};

export const getTimeFromNow = (timestamp) => {
  return moment.unix(timestamp).fromNow();
};

export const getTimestampString = (timestamp) => {
  return `${getTimeFromNow(timestamp)} (${moment
    .unix(timestamp)
    .format("MMM-DD-YYYY hh:mm:ss A")})`;
};

export const formatHumanReadable = (number, decimals = 2) => {
  let sign = false;
  if (number === 0) return "0";
  number < 0 ? (number = number * -1) : (sign = true);
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["", "K", "M", "B", "T"];

  const i = Math.floor(Math.log(number) / Math.log(k));
  return i < 0
    ? number
    : sign
    ? parseFloat((number / Math.pow(k, i)).toFixed(dm)) + sizes[i]
    : -parseFloat((number / Math.pow(k, i)).toFixed(dm)) + sizes[i];
};

export const truncateAddress = (address) => {
  const firstFiveChars = address.slice(0, 7);
  const lastFiveChars = address.slice(address.length - 6);
  return `${firstFiveChars}...${lastFiveChars}`;
};

export const getCurrentTimestamp = () => {
  let time = new Date();
  var UTCseconds = time.getTime() / 1000;
  return Math.floor(UTCseconds);
};

export const removeNullEntries = (obj) => {
  return Object.fromEntries(
    Object.entries(obj)
      // eslint-disable-next-line no-unused-vars
      .filter(([_, v]) => v != null)
      .map(([k, v]) => [k, v === Object(v) ? removeNullEntries(v) : v])
  );
};

export const isValidAddress = (address) => {
  return ethers.utils.isAddress(address);
};

export const isValidUrl = (url) => {
  var regex =
    /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-/]))?/;
  if (!regex.test(url)) {
    return false;
  } else {
    return true;
  }
};

export const unixToDate = (unix, format = "YYYY-MM-DD") => {
  return dayjs.unix(unix).format(format);
};

export const collectionsMap = [
  { name: "ethCollectionMean" },
  { name: "btcCollectionMean" },
  { name: "goldusd_mean" },
];

export const isAddress = (address) => {
  if (ethers.utils.isAddress(address)) return true;
  return false;
};

export const isTxnHash = (hash) => {
  // check if it has the basic requirements of an Hash
  if (/^0x([A-Fa-f0-9]{64})$/.test(hash)) return true;
  return false;
};

export const isCollection = (collectionInput, collections = []) => {
  if (collections.length > 0) {
    for (let i = 0; i < collections.length; i++) {
      if (
        collections[i].collectionName
          .toLowerCase()
          .indexOf(collectionInput.toLowerCase()) > -1
      ) {
        return true;
      }
    }
  } else {
    //If reducer is empty, fallback to hardcoded collections list
    for (let i = 0; i < collectionsMap.length; i++) {
      if (
        collectionsMap[i].name
          .toLowerCase()
          .indexOf(collectionInput.toLowerCase() > -1)
      ) {
        return true;
      }
    }
  }
  return false;
};

export const compactNumber = (value) => {
  const abs = Math.abs(value);
  if (abs >= 1e9) {
    return `${(value / 1e9).toFixed(abs < 1e10 ? 2 : 1)}B`;
  }
  if (abs >= 1e6) {
    return `${(value / 1e6).toFixed(abs < 1e7 ? 2 : 1)}M`;
  }
  if (abs >= 1e3) {
    return `${(value / 1e3).toFixed(abs < 1e4 ? 2 : 1)}K`;
  }
  return `${value.toFixed(1)}`;
};

export const yaxisFormatterNumber = (value) => {
  return compactNumber(value);
};

// eslint-disable-next-line no-unused-vars
export const yaxisFormatter = (value, ...args) => {
  return formatNumber(value, { currency: true, compact: true });
};

export const tooltipFormatterNumber = (value) => {
  return formatNumber(value);
};

export const tooltipFormatter = (value, name, item) => {
  if (item && item.unit === "%") {
    return value.toFixed(2);
  }
  return formatNumber(value, { currency: true });
};

export const _getCurrencyFmt = (value) => {
  const absValue = Math.abs(value);
  if (absValue < 10) {
    return currencyFmt2;
  } else if (absValue < 1000) {
    return currencyFmt1;
  } else {
    return currencyFmt0;
  }
};

export const formatNumber = (value, opts = {}) => {
  const currency = !!opts.currency;
  const compact = !!opts.compact;

  if (currency && !compact) {
    return _getCurrencyFmt(value).format(value);
  }

  const display = compact
    ? compactNumber(value)
    : _getNumberFmt(value).format(value);
  if (currency) {
    return `$${display}`;
  }
  return display;
};

export const _getNumberFmt = (value) => {
  const absValue = Math.abs(value);
  if (absValue < 10) {
    return numberFmt2;
  } else if (absValue < 1000) {
    return numberFmt1;
  } else {
    return numberFmt0;
  }
};

export const convertAmount = (amount) => {
  const total = ethers.utils.formatEther(amount);
  return total;
};

export const redirectToUser = (address) => {
  window.location.href = `/participants/${address.toLowerCase()}`;
};

export const validateContractErrorToast = (error) => {
  if (error.toString().includes("loadingDefaults")) {
    //MetaMask error
    return { txHash: null };
  } else if (error.code === "CALL_EXCEPTION") {
    //Handle Skale RPC error response
    const testReg = new RegExp("0x[a-fA-F0-9]+");
    let match = testReg.exec(error.message);
    console.log(error?.message, testReg.exec(error.message), match[0]);
    return { txHash: match[0] };
  } else {
    return { txHash: null };
  }
};

export const getsFuelBalance = async (participant) => {
  const chainId = localStorage.getItem("chainId");
  const provider = new ethers.providers.JsonRpcProvider(
    RPC_URLS[chainId] || RPC_URLS["11155420"]
  );
  const balance = await provider.getBalance(participant);
  return ethers.utils.formatEther(balance);
};
